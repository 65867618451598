/* Add a simple fade-in effect to the title */
.form-title {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.form-title.animate {
  opacity: 1;
}

/* Prevent the textarea from being resized manually */
.no-resize {
  width: 100%;  /* Ensure full width */
  max-width: 600px;  /* Ensure the textarea doesn't grow too large on wide screens */
  resize: none;  /* Disable manual resizing */
  box-sizing: border-box; /* Make sure padding doesn't affect the width */
}

/* General Styles for Form */
body, html {
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
}

/* Adjust the form-container to account for the Navbar height */
.form-container {
  position: relative;  /* Change to relative for proper stacking */
  margin-top: 80px;  /* Add space at the top equal to the height of the navbar */
  width: 90%;
  max-width: 800px;
  padding: 20px;
  box-sizing: border-box;
  padding-bottom: 50px; /* Adjust margin-top for responsiveness */
}

/* Inputs and Selects */
.form-select,
.form-control {
  width: 100%;
  padding: 12px;
  border-radius: 5px;
  margin: 10px 0;
}

.custom-card {
  width: 100%;
}

.custom-input,
.custom-dropdown {
  width: 100%;
}

/* Buttons */
.rounded-button {
  border-radius: 50px;
  padding: 12px 20px;
  font-size: 16px;
  transition: background-color 0.3s ease;
  margin-top: 20px;
}

/* Layout */
.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensures the page spans the full height */
}

/* Navbar is sticky at the top */
.layout > :first-child {
  flex-shrink: 0; /* Prevents shrinking */
}

/* Main content takes the remaining height */
.main-content {
  flex-grow: 1; /* Fills available space */
  display: flex;
  justify-content: center; /* Centers content horizontally */
  align-items: flex-start; /* Aligns content at the top */
  padding: 20px;
}

.main-content .form-title {
  text-align: center;
}

/* Footer is sticky at the bottom */
.layout > :last-child {
  flex-shrink: 0; /* Prevents shrinking */
}

/* Custom Button for Copy URL */
.p-button-success {
  background-color: #28a745;
  border: none;
  color: white;
  border-radius: 8px;
  padding: 10px 20px;
  transition: background-color 0.3s ease;
}

.p-button-success:hover {
  background-color: #218838;
}

.p-button-danger {
  background-color: #dc3545; /* Red */
}

/* Responsive layout for smaller screens */
@media (max-width: 768px) {
  .form-container {
    padding-bottom: 80px; /* Increase padding for mobile */
  }

  .form-label,
  .form-select,
  .form-control {
    font-size: 14px; /* Adjust font size for better mobile experience */
  }

  .form-group {
    margin-bottom: 15px;
  }

  .card-body .row {
    flex-direction: column;
    align-items: flex-start;
  }

  .col-md-6 {
    width: 100%; /* Stack the inputs on smaller screens */
  }

  .no-resize {
    width: 100%; /* Ensure textarea is responsive */
  }
}

@media (max-width: 480px) {
  .form-container {
    padding-bottom: 100px; /* More space for very small screens */
  }

  .rounded-button {
    width: 100%; /* Full-width buttons on mobile */
  }

  .form-label,
  .form-select,
  .form-control {
    font-size: 12px; /* Smaller fonts for better readability on small screens */
  }

  .no-resize {
    width: 100%; /* Make sure the textarea is full width */
  }
}

textarea.form-control.no-resize {
  width: 100%; /* Ensures it fits the container */
  height: 200px; /* Adjust this value for desired height */
  resize: none; /* Prevents resizing by user */
  font-size: 1rem; /* Optional: Adjust text size */
  padding: 10px; /* Optional: Add padding for better readability */
  max-width: none;
}

/* Card styles */
.custom-card {
  width: 100%;
  max-width: 600px; /* Optional: Set a max-width */
  margin: auto;
}

/* Form container */
.form-container {
  width: 90%;
  max-width: 800px;
  margin: auto;
  padding: 20px;
  box-sizing: border-box;
}

.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .form-container {
    padding: 10px;
  }

  .custom-card {
    max-width: 100%;
  }
}

/* Styling for URL box */
.url-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  margin-top: 20px;
  margin-bottom: 50px;
}

.url-box {
  background-color: #f0f8ff;  /* Light blue background */
  border: 1px solid #007bff; /* Blue border */
  border-radius: 8px;
  padding: 15px;
  max-width: 100%;
  word-wrap: break-word;
  box-sizing: border-box;
}

.url-box a {
  color: #007bff;  /* Blue text */
  text-decoration: none;
  font-weight: bold;
  font-size: 16px;
  word-wrap: break-word;
}

.url-box a:hover {
  text-decoration: underline;
}

.p-button-secondary {
  background-color: #f0f0f0; /* Light gray */
  border: 1px solid #ccc;
  color: #333;
}

.p-button-secondary:hover {
  background-color: #e0e0e0; /* Slightly darker gray on hover */
}

/* Toast custom styling */
.p-toast .p-toast-summary {
  font-size: 16px;
  font-weight: bold;
}

.p-toast .p-toast-detail {
  font-size: 14px;
  color: #333;
}

.submit-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px; /* Optional: adjust for spacing */
  margin-bottom: 75px;
}

/* Line under the Submit button */
.result-divider {
  margin-top: 20px;
  border: 1px solid #ccc; /* Light gray line */
  width: 100%;
}

/* Center the response message and URL container */
.response-message {
  text-align: center;
}

.url-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  margin-top: 20px;
  margin-bottom: 50px;
}

.form-group.mt-4 {
  width: 100%; /* Match the form width */
}

.form-group.mt-4 .p-button {
  width: 100%; /* Ensure the button spans the container */
}

/* Full page layout */
.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Full viewport height */
}

.layout > :first-child {
  flex-shrink: 0; /* Navbar is fixed height */
}

.main-content {
  flex-grow: 1; /* Fill remaining space */
  margin-top: 60px; /* Adjust based on navbar height */
  padding: 20px;
  overflow-y: auto; /* Enable scrolling for content */
}
