/* Ensure the layout spans the full viewport height */
.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Full viewport height */
}

.content-wrap {
  flex: 1; /* Allow content to grow and take available space */
}

/* General container styles */
.about-container {
  padding: 20px;
  max-width: 800px;
  /* margin: 0 auto; */
  font-family: Arial, sans-serif;
  line-height: 1.6;
}

/* Title styles */
.about-title {
  font-size: 2rem;
  text-align: center;
  margin-bottom: 20px;
}

/* Paragraph and list styles */
.about-container p,
.about-container ul {
  font-size: 1rem;
  color: #333;
}

/* List styles */
.about-container ul {
  list-style-type: disc;
  margin-left: 20px;
}

/* Headings */
.about-container h2 {
  font-size: 1.5rem;
  margin-top: 20px;
  color: #007bff;
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  .about-container {
    padding: 15px;
  }

  .about-title {
    font-size: 1.5rem;
  }

  .about-container h2 {
    font-size: 1.2rem;
  }

  .about-container p,
  .about-container ul {
    font-size: 0.9rem;
  }
}
