.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #fff; /* Background color for the loading screen */
  }
  
  .header-image-loading {
    width: 150px; /* Adjust the size of your image */
    height: auto; /* Maintain aspect ratio */
    margin-bottom: 20px; /* Space between image and dots */
  }
  
  .dots {
    display: flex;
    justify-content: center;
  }
  
  .dot {
    width: 10px;
    height: 10px;
    background-color: #3ccce6;
    border-radius: 50%;
    margin: 0 5px;
    animation: bounce 1.5s infinite ease-in-out;
  }
  
  .dot1 {
    animation-delay: 0s;
  }
  
  .dot2 {
    animation-delay: 0.3s;
  }
  
  .dot3 {
    animation-delay: 0.6s;
  }
  
  @keyframes bounce {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
  }
  