.not-found {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(135deg, #ff7e5f, #feb47b);
    color: #fff;
    text-align: center;
    font-family: 'Arial', sans-serif;
  }
  
  .not-found-content {
    max-width: 600px;
    padding: 20px;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 10px;
  }
  
  .not-found h1 {
    font-size: 8rem;
    margin: 0;
  }
  
  .not-found p {
    font-size: 1.5rem;
    margin: 20px 0;
  }
  
  .btn-home {
    display: inline-block;
    padding: 10px 20px;
    background: #fff;
    color: #ff7e5f;
    font-size: 1rem;
    text-decoration: none;
    border-radius: 5px;
    transition: background 0.3s ease, color 0.3s ease;
  }
  
  .btn-home:hover {
    background: #ff7e5f;
    color: #fff;
  }
  