/* Container for centering */
.result-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
  background-color: #f9f9f9; /* Light background for contrast */
  margin: 0;
}

/* Card styling */
.result-card {
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px 40px;
  max-width: 600px;
  width: 100%;
  height: 80vh; /* Set a height to limit the card size */
  overflow-y: auto; /* Enable scrolling for the entire card */
  text-align: left;
  margin-top: 50px;
}

/* Comments Section Styling */
.section-content {
  display: flex;
  align-items: flex-start;
  margin-top: 10px;
}

.line-numbers {
  text-align: right;
  margin-right: 10px;
  user-select: none;
}

.line-numbers a {
  display: block;
  padding: 0 5px;
  color: #999;
  text-decoration: none;
  font-family: 'Courier New', Courier, monospace;
}

.line-numbers a:hover {
  color: #333;
}

.code-content {
  flex: 1;
  background: #f0f0f0;
  border-radius: 5px;
  max-height: 60vh; /* Set a max height for the code content */
  overflow-y: auto; /* Enable vertical scrolling */
  padding: 10px;
}

.editor {
  padding: 10px;
  margin: 0;
  font-family: 'Courier New', Courier, monospace;
  font-size: 16px;
  line-height: 1.5;
}

.code-content pre,
.code-content code {
  white-space: pre-wrap; /* Preserve spaces and line breaks */
  word-wrap: break-word; /* Ensure long text breaks into the next line */
  font-family: monospace; /* Optional: Use a monospaced font for better readability */
  line-height: 1.5; /* Adjust line spacing */
}

/* Line Numbers Styling */
.line-numbers {
  display: flex;
  flex-direction: column;
  text-align: right;
  margin-right: 10px;
  font-family: monospace;
  color: #6c757d; /* Gray color for line numbers */
}

.line-numbers a {
  text-decoration: none;
  color: inherit;
}

.line-numbers a:hover {
  text-decoration: underline;
  color: #007bff; /* Highlight color on hover */
}

/* Action Buttons */
.result-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.action-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.download-button {
  background-color: #007bff;
  color: white;
}

.download-button:hover {
  background-color: #0056b3;
}

.copy-button {
  background-color: #28a745;
  color: white;
}

.copy-button:hover {
  background-color: #1e7e34;
}


.result-container {
  padding: 20px;
  font-family: Arial, sans-serif;
}

.result-card {
  max-width: 600px;
  margin: 0 auto;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  background-color: #f9f9f9;
  margin-top: 50px;
}

.result-item {
  margin-bottom: 15px;
}

.label {
  font-weight: bold;
  margin-right: 10px;
}

.value {
  color: #333;
}

.section-title {
  font-size: 20px;
  margin-bottom: 10px;
}

.text-content {
  font-family: monospace;
  background-color: #eee;
  padding: 10px;
  border-radius: 4px;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.result-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.action-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.download-button {
  background-color: #007bff;
  color: white;
}

.copy-button {
  background-color: #28a745;
  color: white;
}

.action-button:hover {
  opacity: 0.9;
}

.expired-message-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  background-color: #f9f9f9;
}

.expired-message {
  max-width: 600px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.timeout-error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  background-color: #f9f9f9;
}

.timeout-error {
  max-width: 600px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}




.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Full viewport height */
}

.content {
  flex: 1; /* Expand content to push footer down */
  padding: 20px;
  background-color: #f9f9f9;
}

.result-card {
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px 40px;
  max-width: 600px;
  margin: 0 auto; /* Center card horizontally */
  text-align: left;
  margin-top: 50px;
}
