/* Base styles for the navigation bar */
.nav {
  display: flex;
  justify-content: flex-start; /* Align all items to the left */
  align-items: center;
  list-style-type: none;
  padding: 10px 20px;
  margin: 0;
  background-color: #f8f9fa;
  width: 100%;
  position: fixed; /* Ensures Navbar stays fixed at the top */
  top: 0;
  z-index: 1000; /* Keeps Navbar above other elements */
  border-bottom: 1px solid #dee2e6;
  height: 60px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Optional shadow for better visibility */
}

/* Flex container styles for nav sections */
.nav-left,
.nav-center,
.nav-right {
  display: flex;
  align-items: center;
}

/* Ensure all items are in a single row */
.nav-left,
.nav-center,
.nav-right {
  margin: 0;
}

/* Navigation item styles */
.nav-item {
  margin-right: 20px; /* Add spacing between items */
}

.nav-link {
  text-decoration: none;
  color: #007bff;
}

/* Logo styles */
.logo {
  height: 40px;
  margin-right: 15px; /* Space between logo and other items */
  cursor: pointer;
  margin-left: 20px;
}
