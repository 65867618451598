.footer {
  background-color: #f1f1f1;
  color: #333;
  text-align: center;
  padding: 20px 10px;
  position: relative;
  width: 100%;
  font-size: 14px;
  box-sizing: border-box;
}

.footer-content {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px 20px;
}

.footer-about, .footer-links {
  flex: 1;
  margin: 10px;
  min-width: 200px;
}

.footer-about h4, .footer-links h4 {
  font-size: 16px;
  margin-bottom: 10px;
}

.footer-about ul, .footer-links ul {
  list-style: none;
  padding: 0;
}

.footer-about ul li, .footer-links ul li {
  margin-bottom: 5px;
  font-size: 14px;
}

.footer-links ul li a {
  text-decoration: none;
  color: #007BFF;
}

.footer-links ul li a:hover {
  text-decoration: underline;
}

.footer-bottom {
  border-top: 1px solid #ddd;
  padding-top: 10px;
  margin-top: 10px;
}

@media (max-width: 480px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
  }

  .footer-about, .footer-links {
    text-align: center;
  }

  .footer {
    font-size: 12px;
    padding: 15px 10px;
  }
}
